import React, { InputHTMLAttributes, useState } from 'react';

interface CurrencyInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
    label: string;
    errorMessage?: string;
    className?: string;
    prefix?: string;
    value?: number;
    onChange?: (value: number) => void;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({ label, errorMessage, className, prefix = 'R$', value = 0, onChange, ...restProps }) => {
    const [displayValue, setDisplayValue] = useState(formatCurrency(value));

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numericValue = e.target.value.replace(/[^0-9]/g, '');
        const formattedValue = formatCurrency(Number(numericValue) / 100);

        setDisplayValue(formattedValue);
        if (onChange) {
            onChange(Number(numericValue) / 100);
        }
    };

    function formatCurrency(value: number): string {
        return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    let textColor = "text-gray-900";
    let focusColor = "focus:border-blue-600";
    let peerFocusColor = "peer-focus:text-blue-600";

    if (errorMessage) {
        textColor = "text-red-700";
        focusColor = "focus:border-red-600";
        peerFocusColor = "peer-focus:text-red-600";
    }

    return (
        <div className={`relative ${className}`}>
            <div className="flex items-center">
                <span className="mr-2 text-sm text-gray-500">{prefix}</span>
                <input
                    type="text"
                    value={displayValue}
                    onChange={handleInputChange}
                    className={`block py-2.5 px-0 w-full text-sm ${textColor} bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 ${focusColor} peer`}
                    {...restProps}
                />
            </div>
            <label
                htmlFor={restProps.id}
                className={`absolute text-sm text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 ${peerFocusColor} peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}
            >
                {label}
            </label>
            {errorMessage && <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p>}
        </div>
    );
};

export default CurrencyInput;
