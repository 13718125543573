import { useRef } from 'react';
import PageForm from '../../templates/PageForm';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSignal } from '@preact/signals-react';
import CurrencyInput from '../../molecules/CurrencyInput';
import TextEdit from '../../molecules/TextEdit';
import useFetch from '../../atoms/useFetch';
import { IoArrowRedo, IoArrowUndo } from 'react-icons/io5';
import { MdChecklistRtl, MdOutlineCheck } from 'react-icons/md';
import AnalysisRecommendationIcon from '../../ions/AnalysisRecommendationIcon';
import { Auth } from 'aws-amplify';

const ANALYSIS_TYPE = ['form-analysis-approve', 'form-analysis-reject', 'form-analysis-manual-approve', 'form-analysis-manual-reject'];
const PARTNER_ANALYSIS_LIST_PAGE = "/referrals/partner-analysis/0/100/referral.id/ASC";
const PARTNER_ANALYSIS_FORM_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/referrals/partner-analysis-form`;
const PARTNER_ANALYSIS_POST_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/analysis/partner-analysis`;

const titleMap: { [key: string]: string } = {
    'form-analysis-approve': 'Aprovação de análise',
    'form-analysis-reject': 'Reprovação de análise',
    'form-analysis-manual-approve': 'Aprovação manual de análise',
    'form-analysis-manual-reject': 'Reprovação manual de análise'
};

interface Reason {
    id: number;
    description: string;
}

interface Analysis {
    socialId: string;
    createdAt: string;
    farmerName: string;
    partnerAvalistName: string
    partnerOriginName: string
    suggestedValue: number;
    ruralCreditLimit: number;
    reasons: Reason[];
    recommendationCode: string;
    recommendation: string;
}

type Form = {
    referralId: string;
    action: string;
    ruralCreditLimit: number;
    suggestedValue: number;
    reasons: Reason[];
}

type ErrorField = {
    fieldName: string;
    message: string;
}

const PartnerAnalysisForm = () => {

    const navigate = useNavigate();
    const { id = "", type = "" } = useParams();

    if (!id || !ANALYSIS_TYPE.includes(type)) navigate("/not-found");

    const title = `${titleMap[type] || 'Tipo desconhecido'} #${id}`;

    const isLoadingForm = useSignal(false);

    const form = useSignal<Form>({
        referralId: id,
        action: type.replace("form-", ""),
        ruralCreditLimit: 0,
        suggestedValue: 0,
        reasons: []
    });

    const errorFields = useSignal<ErrorField[]>([]);
    const { isLoading, data, error, isTokenError } = useFetch(`${PARTNER_ANALYSIS_FORM_URL}/${type.replace("form-", "")}/${id}`);

    const apiVersion = useRef('');
    const analysis = useSignal<Analysis>({
        socialId: "",
        createdAt: "",
        farmerName: "",
        partnerAvalistName: "",
        partnerOriginName: "",
        suggestedValue: 0,
        ruralCreditLimit: 0,
        reasons: [],
        recommendationCode: "",
        recommendation: ""
    });

    if (data) {
        apiVersion.current = `${data.apiName} - ${data.apiVersion}`;
        analysis.value = data.presenter;
        console.log(analysis.value);
    }

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    const handleAddReason = (reason: Reason) => {

        if (!form.value.reasons.includes(reason)) {
            form.value = { ...form.value, reasons: [...form.value.reasons, reason] };
        }
    };

    const handleRemoveReason = (reason: Reason) => {
        form.value = { ...form.value, reasons: form.value.reasons.filter(r => r.id !== reason.id) };
    };

    //TODO utils
    const changeForm = (field: string, value: any) => {

        form.value = { ...form.value, [field]: value };

        removeErrorField(field);
    }

    //TODO utils
    const removeErrorField = (fieldName: string) => {
        if (errorFields.value === null) return;

        const idx = errorFields.value
            .findIndex(error => error.fieldName === fieldName);

        if (idx !== -1) {
            errorFields.value.splice(idx, 1);
        }
    }

    const submitForm = async () => {
        isLoadingForm.value = true;

        const token = await Auth.currentSession()
            .then((user) => user.getAccessToken()
                .getJwtToken());

        const response = await fetch(PARTNER_ANALYSIS_POST_URL, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form.value)
        });

        if (response.ok) {
            toast.success("Registro aprovado com sucesso!");
            navigate(-1);
        } else {
            const { message, errors, title } = await response.json();
            toast.error(message);
            errorFields.value = errors ? errors : [];

            if (response.status === 413) {
                errorFields.value.push({ fieldName: 'file', message: title });
            }
        }
        isLoadingForm.value = false;
    };

    const cancelForm = () => {
        navigate(PARTNER_ANALYSIS_LIST_PAGE);
    }

    const formatCurrency = (value: number) => {
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);

        return formattedValue.replace(/^R\$\s?/, '');
    };

    return (
        <PageForm
            pageTitle={title}
            apiVersion={apiVersion.current}
            isLoading={isLoading}
            breadcrumb={[{ label: 'Pedidos da revenda', url: PARTNER_ANALYSIS_LIST_PAGE }, { label: title, url: '#' }]}
        >
            {{
                controlSlot: (
                    <div className='pt-4 flex justify-end space-x-2'>
                        <button type='button'
                            className='text-black bg-gradient-to-r from-slate-200 via-slate-300 to-slate-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed'
                            onClick={cancelForm}
                            disabled={isLoadingForm.value || isLoadingForm.value}
                        >
                            <div className="flex items-center">
                                Cancelar
                            </div>
                        </button>

                        {(type === 'form-analysis-approve') && (
                            <button
                                type='button'
                                className={`text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                onClick={submitForm}
                                disabled={isLoadingForm.value}
                            >
                                Aprovar
                            </button>
                        )}

                        {(type === 'form-analysis-reject') && (
                            <button
                                type='button'
                                className={`text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                onClick={submitForm}
                                disabled={isLoadingForm.value}
                            >
                                Reprovar
                            </button>
                        )}

                        {(type === 'form-analysis-manual-approve') && (
                            <button
                                type='button'
                                className={`text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                onClick={submitForm}
                                disabled={form.value.reasons.length == 0 || isLoadingForm.value}
                            >
                                Aprovar manualmente
                            </button>
                        )}

                        {(type === 'form-analysis-manual-reject') && (
                            <button
                                type='button'
                                className={`text-white bg-gradient-to-r from-pink-600 via-pink-700 to-pink-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                onClick={submitForm}
                                disabled={form.value.reasons.length == 0 || isLoadingForm.value}
                            >
                                Reprovar manualmente
                            </button>
                        )}
                    </div>
                ),
                formSlot: (
                    <div className="container mx-auto px-4 py-8 flex">
                        <div className="w-2/3">
                            <div className="grid grid-cols-4 gap-4 mb-4">
                                <div className="col-span-1">
                                    <TextEdit label="Data do pedido:" value={analysis.value.createdAt} disabled />
                                </div>
                                <div className="col-span-1">
                                    <TextEdit label="CPF:" value={analysis.value.socialId} disabled />
                                </div>
                                <div className="col-span-2">
                                    <TextEdit label="Nome do produtor:" value={analysis.value.farmerName} disabled />
                                </div>
                            </div>

                            <div className="mb-4">
                                <div className="col-span-2">
                                    <TextEdit label="Parceira de origem:" value={analysis.value.partnerOriginName} disabled />
                                </div>
                            </div>

                            <div className="mb-4">
                                <div className="col-span-2">
                                    <TextEdit label="Parceira avalista:" value={analysis.value.partnerAvalistName} disabled />
                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-4 mb-4">
                                <div className='col-span-4'>
                                    {(type === 'form-analysis-manual-approve' || type === 'form-analysis-manual-reject') && (
                                        <CurrencyInput label='Valor sugerido:'
                                            prefix='R$'
                                            value={form.value.suggestedValue}
                                            onChange={(value) => changeForm("suggestedValue", Number(value))}
                                        />
                                    )}

                                    {(type === 'form-analysis-approve' || type === 'form-analysis-reject') && (
                                        <TextEdit prefix='R$' label="Valor sugerido:" value={formatCurrency(analysis.value.suggestedValue)} disabled />
                                    )}
                                </div>
                                <div className='col-span-2'>
                                    <AnalysisRecommendationIcon
                                        className='text-3xl'
                                        code={analysis.value.recommendationCode}
                                        description={analysis.value.recommendation} />
                                </div>
                                <div className='col-span-4'>
                                    {(type === 'form-analysis-manual-approve' || type === 'form-analysis-manual-reject') && (
                                        <CurrencyInput label='Limite de crédito rural:'
                                            prefix='%'
                                            value={form.value.ruralCreditLimit}
                                            onChange={(value) => changeForm("ruralCreditLimit", Number(value))}
                                        />
                                    )}

                                    {(type === 'form-analysis-approve' || type === 'form-analysis-reject') && (
                                        <TextEdit prefix='%' label="Limite de crédito rural:" value={analysis.value.ruralCreditLimit} disabled />
                                    )}
                                </div>
                            </div>

                            <div id="reasons" className="mb-4 flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                                {form.value.reasons.length === 0 && (
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <MdChecklistRtl className="text-4xl text-gray-500 " />
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-semibold">Motivo(s) selecionados para aprovação.</span>
                                        </p>
                                    </div>
                                )}
                                <ul>
                                    {form.value.reasons.map((reason) => (
                                        <li key={`selected-reason-${reason.id}`}
                                            onClick={() => handleRemoveReason(reason)}
                                            className="cursor-pointer p-2 border border-blue-300 rounded m-2 bg-white">
                                            <div className="flex items-center justify-between min-w-[450px]">
                                                <span>{reason.description}</span>
                                                <IoArrowRedo className="text-red-800 ml-2" />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="w-1/3 pl-4">
                            <h3 className='font-bold'>Motivos para aprovação</h3>
                            <ul className="border border-blue-300 p-2 rounded">
                                {analysis.value.reasons.map((reason) => (
                                    <li
                                        key={`reason-${reason.id}`}
                                        onClick={() => handleAddReason(reason)}
                                        className={`p-2 m-1 border border-blue-100 ${form.value.reasons.includes(reason) ? 'text-gray-400 bg-slate-50' : 'bg-white cursor-pointer hover:border-blue-500'}`}
                                    >
                                        <div className="grid grid-cols-10">
                                            {form.value.reasons.includes(reason) ?
                                                < MdOutlineCheck />
                                                : <IoArrowUndo className="col-span-1 text-lime-700" />}
                                            <span className='col-span-9'>{reason.description}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )
            }}
        </PageForm >
    );
};

export default PartnerAnalysisForm;
